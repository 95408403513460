@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .heading {
    @apply uppercase bg-black px-6 py-3 text-[#BCBCBC]
  }

  .btn {
    @apply px-4 py-2 rounded-lg font-medium inline-flex items-center justify-center tracking-tight
  }

  .btn-primary {
    @apply bg-[#C7EA46] text-[#080808] hover:bg-[#D1EE68]
  }

  .btn-secondary {
    @apply bg-[#1b1b1b] text-[#BCBCBC] hover:bg-[#383838]
  }

  .btn-outline {
    @apply bg-transparent text-[#080808]
  }

  .section-heading {
    @apply max-w-[840px] mx-auto
  }

  .nav-link {
    @apply relative text-[#bcbcbc] transition-colors duration-300;
  }
  
  .nav-link:hover {
    @apply text-white;
  }
  
  .nav-link::after {
    content: "";
    @apply absolute bottom-[-2px] left-0 w-full h-[1px] bg-white scale-x-0 transition-transform duration-300;
    transform-origin: left; /* Starts growing from the left */
  }
  
  .nav-link:hover::after {
    transform: scaleX(1); /* Expands the line to full width */
  }
  
  .nav-link::after {
    @apply transition-transform duration-300 ease-out;
    transform: scaleX(0); /* Keeps the shrinking direction left to right */
  }
}


body {
  font-family: Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

.container {
  padding: 25px;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
